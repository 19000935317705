interface Desc {
  title: string;
  desc: string[][];
  techno: string[];
  gal?: string[];
}

interface Data {
  type: string;
  img?: string;
  alt?: string;
  links?: string[];
  data?: Desc;
  title?: string;
  role?: string[];
  short?: string[];
  child?: Set;
}

interface Child extends Data {
  parent: string;
}

interface Set {
  [par: string]: Data | Child;
}

const school: Set = {
  Libft: {
    img: require("../assets/prj/1337.png"),
    alt: "1337 Coding School - Libft",
    parent: "1337_Coding_School",
    type: "Project",
    links: ["https://www.github.com/Yassine-K/42_Libft"],
    data: {
      gal: [require("../assets/prj/dev/1337/42_Libft.png")],
      title: "Libft-42",
      desc: [
        [
          "p",
          `This project is about redoing a set of functions from the libc. My functions will have the
          same prototypes and implement the same behaviors as the originals. They must comply
          with the way they are defined in their man. The only difference will be their names. They
          will begin with the ’ft_’ prefix. For instance, strlen becomes ft_strlen.`,
        ],
        ["heading", `Purpose: `],
        ["p", `Teaches core C programming concepts:`],
        ["li", `Memory management (pointers, malloc, free).`],
        ["li", `String manipulation (functions like strcpy, strcat, strlen).`],
        ["li", `File handling (opening, closing, reading, writing).`],
        ["li", `Data structures (linked lists, stacks, etc.).`],
        ["li", `Provides a foundation for future C projects.`],
        ["li", `Enhances understanding of how standard C libraries work.`],
        [
          "li",
          `Develops best practices for code organization and reusability.`,
        ],
        ["heading", `Functionality:`],
        [
          "p",
          `Libft typically involves implementing functions similar to those found
          in the standard C library but written from scratch by the student.`,
        ],
        ["p", `Examples include functions for:`],
        ["li", `String manipulation (copying, comparing, searching).`],
        ["li", `Memory allocation and deallocation.`],
        ["li", `Integer manipulation (conversion, bitwise operations).`],
        ["li", `Mathematical functions.`],
        ["li", `Custom data structures.`],
        ["heading", `Benefits:`],
        ["li", `Improves C programming skills through practical application.`],
        ["li", `Enhances problem-solving abilities.`],
        ["li", `Provides a reusable library for future projects.`],
        ["li", `Introduces concepts like code documentation and testing.`],
      ],
      techno: ["C", "Bash", "Git"],
    },
    title: "Personal information",
    role: ["1337 Coding School - Libft"],
    short: [
      `Libft ("C Library for Beginners") is a common first project for new programmers.
    Students build their own C library, replicating functions from the standard library. This hands-on approach teaches core concepts like memory management and string manipulation.`,
    ],
  },
  Ft_printf: {
    img: require("../assets/prj/1337.png"),
    alt: "1337 Coding School - Ft_printf",
    parent: "1337_Coding_School",
    type: "Project",
    links: ["https://www.github.com/Yassine-K/42_ft_printf"],
    data: {
      gal: [require("../assets/prj/dev/1337/42_Ft_printf.png")],
      title: "Ft_printf",
      desc: [
        [
          "p",
          "The ft_printf project challenges students to build a mini version of printf within a C library. This custom function, ft_printf, can handle essential format specifiers to print various data types like characters, strings, and integers. It also offers an optional bonus to delve into advanced formatting control, allowing us to customize how the output is displayed. By working on this project, we strengthen our C programming skills in areas like variadic functions, memory management, and library creation.",
        ],
        ["heading", "Purpose:"],
        ["li", `Understand variadic functions in C.`],
        ["li", `Practice writing well-structured and extensible code.`],
        [
          "li",
          `Implement core functionalities like handling format specifiers and printing different data types.`,
        ],
        ["heading", "Functionality:"],
        [
          "li bold",
          "My mini printf must be able to handle these format specifiers to print data:",
        ],
        ["li nest", "%d and %i: Prints an integer."],
        ["li nest", "%s: Prints a string."],
        ["li nest", "%c: Prints a single character."],
        ["li nest", "%p: Prints a pointer in hex format."],
        ["li nest", "%u: Prints an unsigned integer."],
        ["li nest", "%x: Prints an integer in lowercase hex."],
        ["li nest", "%X: Prints an integer in uppercase hex."],
        ["li nest", "%%: Prints a literal percent sign."],
        ["li bold", "Bonus expands my ft_printf with formatting control:"],
        [
          "li nest",
          "Flags (-, 0, .): Control padding (spaces or zeros) and precision (min digits/chars) for output.",
        ],
        [
          "li nest",
          "Field Width (number after %): Sets minimum width for the output.",
        ],
        ["li bold", "Extra Flags (#, +, space):"],
        ["li nest", `#: Prefix hex with "0x", octal (future) with "0".`],
        ["li nest", `+: Force sign (+/-) for numbers.`],
        ["li nest", `space: Add space before positive numbers.`],
        ["heading", `Benefits:`],
        [
          "li nest",
          `Grasp variadic functions, write well-structured code, and practice memory management.`,
        ],
        [
          "li nest",
          `Gain insights into format specifiers and (bonus) advanced formatting control.`,
        ],
        [
          "li nest",
          `Develop skills to handle data types, formatting, and edge cases.`,
        ],
      ],
      techno: ["C", "Bash", "Git"],
    },
    title: "Personal information",
    role: ["1337 Coding School - Ft_printf"],
    short: [
      `The ft_printf project challenges students to recreate a fundamental C library function: printf. This function allows formatted output to the console, taking a format string and a variable number of arguments to be displayed according to the specified format.`,
    ],
  },
  Get_next_line: {
    img: require("../assets/prj/1337.png"),
    alt: "1337 Coding School - Get_next_line",
    parent: "1337_Coding_School",
    type: "Project",
    links: ["https://www.github.com/Yassine-K/42_get_next_line"],
    data: {
      gal: [require("../assets/prj/dev/1337/42_Get_next_line.png")],
      title: "Get_next_line",
      desc: [
        [
          "p",
          `This project focuses on building a function named get_next_line in C. This function isn't part of the standard library, but it serves a crucial purpose: reading a text file line by line, efficiently. Instead of loading the entire file at once, get_next_line retrieves one line at a time,
           making it memory-friendly for handling large files. It tackles the challenge of working with file descriptors and parsing
            text data to extract lines effectively as well as introducing us to static variables and how to use them to our advantage. By working on this project, we gain experience in file I/O operations and memory
             management in C.`,
        ],
        ["heading", "Purpose:"],
        [
          "li",
          "Develop a custom C function named get_next_line for efficient file reading.",
        ],
        ["li", "Read data from a file descriptor (fd) line by line."],
        [
          "li",
          "Allocate memory only for the specific line being read, improving memory efficiency for large files.",
        ],
        ["heading", "Functionality:"],
        [
          "li",
          "Reads from a file descriptor: The function takes an fd (file descriptor) as input and reads data from that file.",
        ],
        [
          "li",
          "Line-by-line reading: It retrieves one line of text at a time, including the newline character (\n) unless the end of file is reached without a newline.",
        ],
        [
          "li",
          "Returns a line: Upon successful retrieval, the function returns a pointer to a character array containing the read line.",
        ],
        [
          "li",
          "Handles end-of-file: If the end of the file is reached, get_next_line returns NULL.",
        ],
        [
          "li",
          "Error handling: In case of an error during the reading process, the function also returns NULL.",
        ],
        [
          "li",
          "Works with standard input: The function can be used to read from both files and standard input (keyboard).",
        ],
        [
          "li",
          "Buffer for efficiency: It utilizes a buffer to improve reading efficiency by minimizing system calls (calls to read). The buffer size can be customized using the -D BUFFER_SIZE=n compiler flag.",
        ],
        ["heading", `Benefits:`],
        [
          "li",
          "Memory efficiency: By reading line by line, get_next_line avoids loading the entire file content into memory, making it suitable for large files.",
        ],
        [
          "li",
          "Flexibility: It can be used with both files and standard input, offering versatility in reading data sources.",
        ],
        [
          "li",
          "Customizable buffer size: The BUFFER_SIZE allows us to adjust the buffer size based on our needs and file characteristics.",
        ],
        [
          "li",
          "Learning experience: Working on this project enhances our understanding of file I/O operations in C, memory management techniques, and handling buffer sizes for efficient reading.",
        ],
      ],
      techno: ["C", "Bash", "Git"],
    },
    title: "Personal information",
    role: ["1337 Coding School - Get_next_line"],
    short: [
      `The get_next_line project makes us build a C function that reads a file line by line, instead of loading the whole thing at once. This is useful for handling large files efficiently`,
    ],
  },
  Born2broot: {
    img: require("../assets/prj/1337.png"),
    alt: "1337 Coding School - Born2broot",
    parent: "1337_Coding_School",
    type: "Project",
    data: {
      gal: [],
      title: "Born2broot",
      desc: [
        [
          "p",
          `The Born2BeRoot project plunges us into the world of system administration. We built a virtual machine and configured a basic server environment from scratch. This hands-on experience involves setting up a secure server with strong passwords, firewalls, and user permissions. We learn to manage resources like partitions and user accounts, while also creating a script to monitor server health. The project offers an optional bonus to delve deeper into advanced partitioning or even setting up a website. By completing Born2BeRoot, we gain valuable practical skills and a foundational understanding of system administration tasks.`,
        ],
        ["heading", "Purpose:"],
        [
          "li",
          "Guide us through setting up a basic server environment within a virtual machine (VM).",
        ],
        ["li", "Provide hands-on experience with system administration tasks."],
        ["heading", "Functionality:"],
        ["li bold", "Virtual Machine Setup:"],
        ["li nest", "Create a VM using VirtualBox or UTM."],
        ["li bold", "Operating System Installation:"],
        ["li nest", "Choose and install Debian or Rocky Linux (Debian in my case)."],
        ["li bold", "Server Configuration:"],
        ["li nest", "Focus on essential functionalities (no graphical interfaces)."],
        ["li nest", "Implement strong password policies for all accounts."],
        ["li nest", "Configure SSH for secure remote access (disallow root login)."],
        [
          "li nest",
          "Set up a firewall (UFW/firewall) and restrict access to port 4242.",
        ],
        [
          "li nest",
          "Manage user accounts (root and my login with user42 and sudo privileges).",
        ],
        ["li nest", "Create encrypted partitions using LVM."],
        ["li bold", "Monitoring Script Development:"],
        [
          "li nest",
          "Build a bash script (monitoring.sh) to display server information periodically.",
        ],
        ["heading", "Bonus:"],
        [
          "li",
          "Setting up a WordPress website with Lighttpd, MariaDB, and PHP.",
        ],
        ["li", "Implementing and justifying the use of a chosen service."],
        ["heading", `Benefits:`],
        ["li", "Gain practical skills in system administration."],
        ["li", "Learn essential server security practices."],
        ["li", "Understand core server components and their roles."],
        ["li", "Develop a monitoring script for basic server health checks."],
      ],
      techno: ["Bash", "Git"],
    },
    title: "Personal information",
    role: ["1337 Coding School - Born2broot"],
    short: [
      ` This project aims to introduce us to the wonderful world of virtualization.
      We have to create our first machine in VirtualBox
      under specific instructions. Then, at the end of this project, we are able to set up
      our own operating system while implementing strict rules.`,
    ],
  },
  So_long: {
    img: require("../assets/prj/1337.png"),
    alt: "1337 Coding School - So_long",
    links: ["https://www.github.com/Yassine-K/42_so_long"],
    parent: "1337_Coding_School",
    type: "Project",
    data: {
      gal: [
        require("../assets/prj/dev/1337/42_So_long_vid.mp4"),
        require("../assets/prj/dev/1337/42_So_long.png"),
      ],
      title: "So_long",
      desc: [
        [
          "p",
          `Up for a game development challenge? The so_long project invites us to build a 2D game entirely in C. Imagine a top-down maze adventure where you control a character, collect items, and navigate to an exit. This project puts us in the programmer's seat, where we write C code to create the game logic, handle character movement, and implement win/lose conditions. We also tackle map rendering, translating a map file into visuals on the screen. Feeling creative? There's an optional bonus to add features like a move counter or visual effects. By completing so_long, we not only have a cool game we created ourselves but also gain valuable experience in C programming and core game development concepts.`,
        ],
        [
          "p",
          `I chose to recreate my favorite Gameboy game "The Legend of Zelda: The Minish Cap" since it aligns with the project requirements, has great graphics, and is fun! I would've spent more time developing the game if I didn't have other projects to complete. I'm proud of what I created using the very limited MLX library which only offers functions to create/destroy the window and display pixels or images.`,
        ],
        ["heading", "Purpose:"],
        ["li", `Develop a basic 2D game called "so_long" entirely in C.`],
        ["li", `Gain practical experience in game development fundamentals.`],
        ["heading", "Functionality:"],
        ["li bold", "2D Game in C: Write C code for game logic, including:"],
        ["li nest", "Character movement across the map."],
        ["li nest", "Collision detection with walls and other obstacles."],
        ["li nest", "Item collection within the game world."],
        [
          "li nest",
          "Win/lose conditions based on reaching the exit and collecting all items.",
        ],
        ["li bold", "Map Rendering:"],
        [
          "li nest",
          "Parse a map file defining the game layout (walls, collectibles, exit).",
        ],
        [
          "li nest",
          "Translate map data into visuals displayed on the screen (using the MLX library).",
        ],
        [
          "li nest",
          "Handle keyboard input to control character movement within the game.",
        ],
        ["li bold", `Enhance the game with additional features like:`],
        ["li nest", `Displaying the number of moves made by the player.`],
        ["li nest", `Implementing visual effects.`],
        ["heading", `Benefits:`],
        ["li bold", `Game Development Skills:`],
        ["li nest", `Gain hands-on experience building a C game.`],
        ["li bold", `C Programming Practice: Solidify C concepts like:`],
        ["li nest", `Memory management.`],
        ["li nest", `User input handling.`],
        ["li nest", `Conditional statements.`],
        ["li bold", `Project Structure and Modularity:`],
        ["li nest", `Learn effective code organization within a project.`],
        ["li bold", `Game Development Concepts: Grasp core game mechanics:`],
        ["li nest", `Map design.`],
        ["li nest", `Character movement.`],
        ["li nest", `Collision detection.`],
      ],
      techno: ["C", "Bash", "Git"],
    },
    title: "Personal information",
    role: ["1337 Coding School - So_long"],
    short: [
      `My favorite project so far! This project challenges us to build a simple 2D game called "so_long" entirely within C. Imagine a top-down maze where we control a character, collect items and reach an exit to win.`,
    ],
  },
  Pipex: {
    img: require("../assets/prj/1337.png"),
    alt: "1337 Coding School - Pipex",
    parent: "1337_Coding_School",
    type: "Project",
    links: ["https://www.github.com/Yassine-K/42_pipex"],
    data: {
      gal: [require("../assets/prj/dev/1337/42_Pipex.png")],
      title: "Pipex",
      desc: [
        [
          "p",
          `Want to be a shell whisperer? The pipex project lets us build a mini pipeline in C. It mimics how shell pipes connect commands, making complex workflows a breeze. Think cat feeding output to grep. This project teaches system programming essentials and lets us craft a powerful tool for the command line.`,
        ],
        ["heading", "Purpose:"],
        [
          "li",
          `Develop a C program named pipex that mimics the functionality of pipes in Unix shells.`,
        ],
        ["li", `Gain practical experience with system programming concepts.`],
        ["heading", "Functionality:"],
        ["li", `Command Execution: Execute two commands provided by the user.`],
        [
          "li",
          `Pipe Creation: Establish a communication channel (pipe) between the commands.`,
        ],
        [
          "li",
          `Data Flow Management: Redirect the output of the first command to the standard input of the second command.`,
        ],
        [
          "li",
          `Error Handling: Implement proper error handling for potential issues like file opening failures or command execution errors.`,
        ],
        ["heading", `Benefits:`],
        ["li bold", `Understanding Pipes:`],
        [
          "li nest",
          `Gain a deeper understanding of how pipes work and their role in chaining commands within the Unix shell.`,
        ],
        [
          "li bold",
          `System Programming Practice: Solidify system programming concepts like:`,
        ],
        ["li nest", `Process creation.`],
        ["li nest", `fork/exec system calls.`],
        ["li nest", `File I/O operations.`],
        ["li bold", `Building Reusable Code:`],
        [
          "li nest",
          `Develop a reusable program (pipex) for various command piping tasks.`,
        ],
      ],
      techno: ["C", "Bash", "Git"],
    },
    title: "Personal information",
    role: ["1337 Coding School - Pipex"],
    short: [
      `The pipex project challenges us to create a program named pipex in C. This program mimics a core functionality of the Unix shell: pipes. Pipes allow us to chain commands together, where the output of one command becomes the input for the next.`,
    ],
  },
  Push_swap: {
    img: require("../assets/prj/1337.png"),
    alt: "1337 Coding School - Push_swap",
    parent: "1337_Coding_School",
    type: "Project",
    links: ["https://www.github.com/Yassine-K/42_push_swap"],
    data: {
      gal: [require("../assets/prj/dev/1337/42_Push_swap.png")],
      title: "Push_swap",
      desc: [
        [
          "p",
          `The Push_Swap project challenges us to think outside the box and design an efficient
         sorting algorithm that works within the limitations of using stacks and a restricted set of
          instructions. It's a hands-on experience in algorithm design and optimization in C.`,
        ],
        ["heading", "Purpose:"],
        [
          "li",
          "Develop a C program (push_swap) to efficiently sort a list of integers.",
        ],
        ["li", `Explore sorting algorithms under specific constraints.`],
        [
          "p",
          `Sort the list using only a specific set of instructions that manipulate two stacks.`,
        ],
        [
          "li",
          `For a minimalist validation (that implies a minimal grade of 80)), we must be
        able to sort 100 random numbers in fewer than 700 operations.`,
        ],
        [
          "li",
          `For maximal project validation and thus to be able to achieve the bonuses, we
        must fulfill the first step above, but also for 500 random numbers, there should
        be no more than 5500 operations.`,
        ],
        ["heading", "Functionality:"],
        ["li bold", `Sorting with Stacks:`],
        ["li nest", `Sort the integer list using two stacks (a and b).`],
        [
          "li bold",
          `Limited Instructions: Utilize a predefined set of instructions to manipulate the stacks:`,
        ],
        ["li nest", `Swapping elements within a stack.`],
        ["li nest", `Pushing elements between stacks.`],
        ["li nest", `Rotating elements within a stack.`],
        ["li bold", `Minimal Instructions:`],
        ["li nest", `Aim to sort the list with the fewest possible instructions.`],
        ["heading", `Benefits:`],
        [
          "li",
          `Problem-Solving Skills: Develop creative solutions to achieve sorting within a restricted environment.`,
        ],
        [
          "li",
          `Algorithm Exploration: Gain experience with sorting algorithms and their efficiency.`,
        ],
        [
          "li",
          `Optimization Techniques: Understand how to optimize algorithms for specific constraints.`,
        ],
        [
          "li",
          `C Programming Practice: Solidify C programming skills like memory management and function implementation.`,
        ],
      ],
      techno: ["C", "Bash", "Git"],
    },
    title: "Personal information",
    role: ["1337 Coding School - Push_swap"],
    short: [
      `The push_swap project is a coding challenge that teaches core concepts in sorting algorithms. We sort a list of numbers
     using only a limited set of operations on two virtual stacks. These operations involve pushing, swapping, and rotating elements.
      The challenge lies in finding the most efficient way to sort the list with the least number of moves, forcing us to consider different algorithms and optimization techniques.`,
    ],
  },
  Minishell: {
    img: require("../assets/prj/1337.png"),
    alt: "1337 Coding School - Minishell",
    parent: "1337_Coding_School",
    type: "Project",
    links: ["https://www.github.com/Yassine-K/42-Minishell"],
    data: {
      gal: [require("../assets/prj/dev/1337/42_Minishell.png")],
      title: "Minishell",
      desc: [
        [
          "p",
          `This project involves developing a custom shell, similar to Unix-based command-line interfaces,
           designed to facilitate user interaction with the operating system. The shell will feature essential
            functionalities like prompt display, command history, executable search and launch, signal handling,
             and environment variable expansion. Additionally, it will support redirections, pipes, built-in commands,
              and logical operators. The shell aims to provide educational value, customization options, and enhanced
               control over command execution and scripting, making it a valuable tool for students, developers, and
                system administrators.`,
        ],
        ["heading", "Purpose:"],
        ["li bold", "Educational Tool:"],
        [
          "li nest",
          "Provides a platform for learning the inner workings of a shell.",
        ],
        [
          "li nest",
          "Helps us students understand key concepts in system-level programming and command-line interfaces.",
        ],
        ["li bold", "Interactive Environment:"],
        [
          "li nest",
          "Offers an interface for executing commands and managing system tasks.",
        ],
        [
          "li nest",
          "Enhances user interaction with the operating system via a command-line interface.",
        ],
        ["li bold", "Customization and Extension:"],
        [
          "li nest",
          "Allows the creation of a tailored shell with specific features and optimizations.",
        ],
        [
          "li nest",
          "Enables the addition of new functionalities to meet user needs or workflows.",
        ],
        ["li bold", "Enhanced Command Control:"],
        [
          "li nest",
          "Improves the ability to manage and execute complex command sequences.",
        ],
        ["li nest", "Facilitates advanced scripting and automation tasks."],
        ["li bold", "Debugging and Optimization:"],
        [
          "li nest",
          "Aids in understanding and improving the performance of shell scripts.",
        ],
        [
          "li nest",
          "Provides insights into common issues and best practices in command execution and script management.",
        ],
        ["li bold", "Scripting Skill Development:"],
        ["li nest", "Enhances proficiency in writing and managing shell scripts."],
        [
          "li nest",
          "Provides hands-on experience with process management, environment variables, and input/output redirection.",
        ],
        ["li bold", "System Interaction:"],
        [
          "li nest",
          "Offers a deeper understanding of how to interact with the Unix/Linux operating system.",
        ],
        [
          "li nest",
          "Helps in mastering the use of environment variables, pipelines, and redirections for efficient system management.",
        ],
        ["li bold", "Consistent User Experience:"],
        [
          "li nest",
          "Ensures a uniform command-line interface across different systems and environments.",
        ],
        [
          "li nest",
          "Provides a customizable and portable shell that can be adapted to various use cases.",
        ],
        ["heading", "Functionality:"],
        ["li bold", "Prompt Display:"],
        [
          "li nest",
          "Shows a prompt when waiting for a new command, making it clear that the shell is ready to accept user input.",
        ],
        ["li bold", "Command History:"],
        [
          "li nest",
          "Maintains a history of commands entered by the user, allowing easy recall and re-execution of previous commands using the up and down arrow keys.",
        ],
        ["li bold", "Executable Search and Launch:"],
        [
          "li nest",
          "Searches and launches the appropriate executable based on the PATH environment variable, or by using relative or absolute paths.",
        ],
        ["li bold", "Signal Handling:"],
        [
          "li nest",
          "Implements robust signal handling to manage interruptions like ctrl-C (to interrupt the current process and show a new prompt), ctrl-D (to exit the shell), and ctrl- (ignored).",
        ],
        ["li bold", "Quote Handling:"],
        [
          "li nest",
          "Single Quotes: Prevents the shell from interpreting meta characters within single quotes.",
        ],
        [
          "li nest",
          "Double Quotes: Prevents the shell from interpreting meta characters within double quotes, except for $ (dollar sign) which is used for variable expansion.",
        ],
        ["li bold", "Redirections:"],
        ["li nest", "Input Redirection (<):"],
        ["li nest", "Redirects input from a file."],
        ["li nest", "Output Redirection (>):"],
        ["li nest", "Redirects output to a file, overwriting the file."],
        ["li nest", "Append Output Redirection (>>):"],
        ["li nest", "Redirects output to a file, appending to the file."],
        [
          "li nest",
          "Here Document (<<): Reads input until a line containing the specified delimiter is seen.",
        ],
        ["li bold", "Pipes (|):"],
        [
          "li nest",
          "Connects the output of one command to the input of the next command, enabling complex command sequences.",
        ],
        ["li bold", "Environment Variable Expansion:"],
        [
          "li nest",
          "Expands environment variables (e.g., $HOME, $PATH) within commands.",
        ],
        ["li bold", "Special Variable Handling:"],
        [
          "li nest",
          "$?: Expands to the exit status of the most recently executed foreground pipeline.",
        ],
        ["li bold", "Built-in Commands:"],
        [
          "li nest",
          "echo: Prints text to the terminal, with support for -n to suppress the trailing newline.",
        ],
        ["li nest", "cd: Changes the current directory."],
        ["li nest", "pwd: Prints the current working directory."],
        ["li nest", "export: Sets environment variables."],
        ["li nest", "unset: Unsets environment variables."],
        ["li nest", "env: Prints the current environment variables."],
        ["li nest", "exit: Exits the shell."],
        ["li bold", "Logical Operators:"],
        [
          "li nest",
          "&&: Executes the second command only if the first command succeeds.",
        ],
        [
          "li nest",
          "||: Executes the second command only if the first command fails.",
        ],
        ["li bold", "Wildcard Expansion:"],
        [
          "li nest",
          "Expands wildcards (*) to match files in the current working directory.",
        ],
        ["heading", `Benefits:`],
        ["li bold", "Educational Value:"],
        [
          "li nest",
          "Understanding how a shell works internally is invaluable for computer science and software engineering students. It demystifies the workings of a key component of Unix-based systems.",
        ],
        ["li bold", "Customization:"],
        [
          "li nest",
          "Users can modify and extend the shell to meet specific needs, adding unique features or optimizing it for particular workflows.",
        ],
        ["li bold", "Enhanced Control:"],
        [
          "li nest",
          "Provides an environment to experiment with shell features and scripting, aiding in the development of more advanced command-line tools and scripts.",
        ],
        ["li bold", "Improved Scripting Skills:"],
        [
          "li nest",
          "Working on a shell project enhances one's skills in scripting, process management, and understanding the Unix/Linux operating system.",
        ],
        ["li bold", "Debugging and Optimization:"],
        [
          "li nest",
          "Learning how to implement a shell helps in debugging and optimizing existing shell scripts, making it easier to understand performance bottlenecks and potential improvements.",
        ],
        ["li bold", "Flexibility and Portability:"],
        [
          "li nest",
          "A custom shell can be tailored to work across different systems and environments, providing a consistent interface and functionality.",
        ],
      ],
      techno: ["C", "Bash", "Git"],
    },
    title: "Personal information",
    role: ["1337 Coding School - Minishell"],
    short: [
      `The MiniShell project provides a valuable learning experience by letting us build a simplified version of a shell program. This project deepens our understanding of how the command line works and equips us with essential system programming skills.`,
    ],
  },
  Philosophers: {
    img: require("../assets/prj/1337.png"),
    alt: "1337 Coding School - Philosophers",
    parent: "1337_Coding_School",
    links: ["https://www.github.com/Yassine-K/42-philosophers"],
    type: "Project",
    data: {
      gal: [require("../assets/prj/dev/1337/42_Philosophers.png")],
      title: "Philosophers",
      desc: [
        [
          "p",
          `This project dives into the classic Dining Philosophers problem with a twist. We build programs to simulate philosophers
         competing for forks (resources) while they eat, think, and sleep. The challenge involves using threads (mutexes) or processes
          (semaphores) to prevent deadlocks and starvation, ensuring all philosophers get to eat their spaghetti!`,
        ],
        ["heading", "Mandatory Part:"],
        [
          "li",
          `Simulate the classic dining philosophers problem with limitations.`,
        ],
        [
          "li",
          `Explore thread and process synchronization techniques for resource management.`,
        ],
        ["heading", "Functionality:"],
        ["li", `Implement philosophers as threads.`],
        [
          "li",
          `Utilize mutexes to prevent multiple philosophers from grabbing the same fork simultaneously.`,
        ],
        [
          "li",
          `Manage philosopher states (eating, thinking, sleeping) and ensure they don't starve (die) waiting for forks indefinitely.`,
        ],
        [
          "li",
          `Log state changes with timestamps and philosopher IDs for monitoring.`,
        ],
        ["heading", "Bonus Part:"],
        ["li", `Implement philosophers as processes.`],
        [
          "li",
          `Use a semaphore to represent the total number of available forks, ensuring fair access.`,
        ],
        [
          "li",
          `Maintain functionalities of the mandatory part but with process-based synchronization.`,
        ],
        ["heading", `Benefits:`],
        ["li", "Understanding of thread/process management"],
        ["li", "Mastery of mutex and semaphore usage"],
        ["li", "Experience with race condition prevention"],
        ["li", "Knowledge of deadlock avoidance"],
        ["li", "Practice with precise timing requirements"],
        ["li", "Development of thread-safe code"],
        ["li", "Skills in concurrent program debugging"],
        ["li", "Real-world synchronization experience"],
      ],
      techno: ["C", "Bash", "Git"],
    },
    title: "Personal information",
    role: ["1337 Coding School - Philosophers"],
    short: [
      `The Dining Philosophers project provides a hands-on learning experience in thread and process synchronization.
     By tackling this problem with limitations, we gain valuable skills in resource management, concurrency concepts, and implementing
      solutions to prevent deadlocks and starvation. It's a challenging but rewarding project that deepens our understanding of synchronization mechanisms in concurrent programming.`,
    ],
  },
  NetPractice: {
    img: require("../assets/prj/1337.png"),
    alt: "1337 Coding School - NetPractice",
    parent: "1337_Coding_School",
    links: ["https://www.github.com/Yassine-K/42-NetPractice"],
    type: "Project",
    data: {
      gal: [require("../assets/prj/dev/1337/42_NetPractice.png")],
      title: "NetPractice",
      desc: [
        [
          "p",
          `Net_Practice provides a valuable learning experience for those interested in network administration. By engaging with
           simulated scenarios, we gain practical skills in applying TCP/IP addressing, configuring network devices, and
            troubleshooting network connectivity problems. This project lays the groundwork for further exploration in network
             engineering and network-related careers.`,
        ],
        ["heading", "Purpose:"],
        [
          "li",
          `Introduce fundamental network concepts through practical exercises.`,
        ],
        [
          "li",
          `Equip students with skills in network configuration and troubleshooting.`,
        ],
        [
          "li",
          `Provide a platform for hands-on exploration of TCP/IP addressing.`,
        ],
        ["heading", "Functionality:"],
        [
          "li",
          `Simulated Network Environment: Students work within a web-based training interface, configuring virtual network devices.`,
        ],
        [
          "li",
          `10 Levels of Exercises: The project progresses through 10 difficulty levels, each presenting a network with specific issues to resolve.`,
        ],
        [
          "li",
          `Configuration Tools: The interface allows users to modify network settings for devices like routers.`,
        ],
        [
          "li",
          `Verification and Download: Students can verify their configurations and download them for submission.`,
        ],
        [
          "li",
          `Troubleshooting Logs: Logs provide clues to identify and rectify configuration errors.`,
        ],
        ["heading", `Benefits:`],
        [
          "li",
          `Practical Network Configuration: Students gain hands-on experience in setting up and managing simulated networks.`,
        ],
        [
          "li",
          `TCP/IP Addressing Mastery: The project emphasizes understanding and applying TCP/IP addressing principles.`,
        ],
        [
          "li",
          `Troubleshooting Skills Development: By fixing network issues within the exercises, students build problem-solving and troubleshooting skills.`,
        ],
        [
          "li",
          `Preparation for Real-World Networks: The project serves as a stepping stone for working with actual network hardware.`,
        ],
        [
          "li",
          `Peer Evaluation and Defense: The process of explaining solutions to peers during defense reinforces learning.`,
        ],
      ],
      techno: ["Cplusplus", "Bash", "Git"],
    },
    title: "Personal information",
    role: ["1337 Coding School - NetPractice"],
    short: [
      `Net_Practice boosts our network admin skills and takes us through 10 levels of simulated network challenges. We configure virtual routers, troubleshoot connectivity issues, and master TCP/IP addressing. With hands-on practice and peer evaluation, Net_Practice equips us to confidently navigate the world of network administration.`,
    ],
  },
  Cub3d: {
    img: require("../assets/prj/1337.png"),
    alt: "1337 Coding School - Cub3d",
    parent: "1337_Coding_School",
    links: ["https://www.github.com/Yassine-K/42-Cub3d"],
    type: "Project",
    data: {
      gal: [require("../assets/prj/dev/1337/42_Cub3D.png")],
      title: "Cub3d",
      desc: [
        [
          "p",
          `Dive into the world of 3D game development with Cub3D! This project lets us build a raycasting engine from scratch in C.
         Explore a maze environment, rendered using raycasting techniques to simulate 3D graphics. The core functionalities involve
          parsing a map layout, calculating intersections with walls, and transforming that data into a 2D representation.
           But that's just the beginning! Cub3D offers an optional bonus section where we can experiment with lighting models,
            introduce enemies and items, and even create a multiplayer mode.`,
        ],
        ["heading", "Purpose:"],
        [
          "li",
          `Develop a game engine named cub3d in C that renders a 3D maze environment using raycasting.`,
        ],
        [
          "li",
          `Gain practical experience in fundamental 3D graphics concepts.`,
        ],
        ["heading", "Functionality:"],
        [
          "li",
          `Raycasting Engine: Simulate 3D graphics by calculating intersections between rays and walls.`,
        ],
        [
          "li",
          `Map Parsing: Read a maze description file defining the layout (walls, textures, player position).`,
        ],
        [
          "li",
          `3D to 2D Projection: Translate raycasting results into a 2D image for display.`,
        ],
        [
          "li",
          `Textured Walls: Apply different textures to walls based on their direction.`,
        ],
        [
          "li",
          `Floor & Ceiling: Set distinct colors for the floor and ceiling.`,
        ],
        [
          "li",
          `Player Movement: Control player movement using the W, A, S, D keys.`,
        ],
        [
          "li",
          `Player Rotation: Rotate player's viewpoint left and right using arrow keys.`,
        ],
        [
          "li",
          `User Interface: Display the rendered maze in a responsive window.`,
        ],
        [
          "li",
          `Error Handling: Detect and display error messages during file parsing.`,
        ],
        [
          "li",
          `Wall Collisions: Prevent the player from walking through walls.`,
        ],
        [
          "li",
          `Minimap System: Display a minimap for navigation within the maze.`,
        ],
        [
          "li",
          `Interactive Doors: Implement doors that open and close upon player interaction.`,
        ],
        [
          "li",
          `Animated Sprites: Introduce animated objects like enemies within the maze.`,
        ],
        [
          "li",
          `Mouse Control: Enable player viewpoint rotation using mouse movement.`,
        ],
        ["heading", `Benefits:`],
        [
          "li",
          `3D Graphics with Raycasting: Gain practical experience with raycasting techniques for 3D rendering.`,
        ],
        [
          "li",
          `Game Engine Development Fundamentals: Understand the core concepts of building a basic game engine.`,
        ],
        [
          "li",
          `C Programming Skills: Solidify our C programming skills in areas like memory management, data structures, and graphics rendering.`,
        ],
        [
          "li",
          `Problem-Solving: Approach challenges in implementing game mechanics and maze functionalities.`,
        ],
        [
          "li",
          `Optional Exploration: Gain exposure to advanced topics like collision detection, minimap design, and animation.`,
        ],
      ],
      techno: ["C", "Bash", "Git"],
    },
    title: "Personal information",
    role: ["1337 Coding School - Cub3d"],
    short: [
      `This project challenges us to create a program named cub3D that renders a first-person perspective view inside a maze using raycasting techniques. It utilizes the MinilibX library for window management and graphics.`,
    ],
  },
  CPP_Modules: {
    img: require("../assets/prj/1337.png"),
    alt: "1337 Coding School - CPP Modules (CPP 00-09)",
    parent: "1337_Coding_School",
    links: ["https://github.com/Yassine-K/CPP-Moduels"],
    type: "Project",
    data: {
      gal: [
        require("../assets/prj/dev/1337/42_CPP00.png"),
        require("../assets/prj/dev/1337/42_CPP01.png"),
      ],
      title: "CPP Modules",
      desc: [
        [
          "p",
          `The CPP modules transition students from C to C++, introducing Object-Oriented Programming concepts progressively. Each module builds upon the previous one, covering fundamental to advanced C++ features, best practices, and modern C++ standards.`,
        ],
        ["heading", "CPP00: First Steps into C++"], //CPP00
        ["li bold", `Key Concepts:`],
        ["li nest", `Classes, Objects, Member functions, IO Streams`],
        ["li bold", `Main Projects:`],
        ["li nest", `Megaphone (String manipulation)`],
        ["li nest", `PhoneBook (Class implementation)`],
        [
          "li nest",
          `Account reconstruction (Header/Source files relationship)`,
        ],
        ["heading", "CPP01: Memory and References"], //CPP01
        ["li bold", `Key Concepts:`],
        ["li nest", `Memory allocation, References vs Pointers, File streams.`],
        ["li bold", `Main Projects:`],
        ["li nest", `Zombie management (Memory allocation)`],
        ["li nest", `File replacement program (File handling)`],
        ["li nest", `Harl (Member function pointers)`],
        ["heading", "CPP02: Ad-hoc Polymorphism"], //CPP02
        ["li bold", `Key Concepts:`],
        ["li nest", `Operator overloading, Fixed-point arithmetic.`],
        ["li bold", `Main Projects:`],
        ["li nest", `Fixed-point number class`],
        ["li nest", `Operator overloading implementation`],
        ["li nest", `BSP (Binary Space Partitioning)`],
        ["heading", "CPP03: Inheritance"], //CPP03
        ["li bold", `Key Concepts:`],
        ["li nest", ` Class inheritance, Virtual functions.`],
        ["li bold", `Main Projects:`],
        ["li nest", `ClapTrap and derived classes`],
        ["li nest", `Multiple inheritance`],
        ["li nest", `Diamond problem resolution`],
        ["heading", "CPP04: Polymorphism"], //CPP04
        ["li bold", `Key Concepts:`],
        ["li nest", `Abstract classes, Interfaces, Deep/Shallow copy.`],
        ["li bold", `Main Projects:`],
        ["li nest", `Animal classes hierarchy`],
        ["li nest", `Brain implementation (Deep copy)`],
        ["li nest", `Interface implementation`],
        ["heading", "CPP05: Exceptions"], //CPP05
        ["li bold", `Key Concepts:`],
        ["li nest", `Exception handling, Custom exceptions.`],
        ["li bold", `Main Projects:`],
        ["li nest", `Bureaucrat system`],
        ["li nest", `Form validation`],
        ["li nest", `Exception hierarchy`],
        ["heading", "CPP06: Casting"], //CPP06
        ["li bold", `Key Concepts:`],
        ["li nest", `Type conversion, RTTI.`],
        ["li bold", `Main Projects:`],
        ["li nest", `Scalar conversion`],
        ["li nest", `Serialization`],
        ["li nest", `Dynamic type identification`],
        ["heading", "CPP07: Templates"], //CPP07
        ["li bold", `Key Concepts:`],
        ["li nest", `Generic programming, Template specialization.`],
        ["li bold", `Main Projects:`],
        ["li nest", `Function templates`],
        ["li nest", `Iterator implementation`],
        ["li nest", `Array template class`],
        ["heading", "CPP08: STL Containers"], //CPP08
        ["li bold", `Key Concepts:`],
        ["li nest", `Standard Template Library, Iterators.`],
        ["li bold", `Main Projects:`],
        ["li nest", `Container algorithms`],
        ["li nest", `Span implementation`],
        ["li nest", `Stack operations`],
        ["heading", "CPP09: Advanced STL"], //CPP09
        ["li bold", `Key Concepts:`],
        ["li nest", `Container adaptors, Complex algorithms.`],
        ["li bold", `Main Projects:`],
        ["li nest", `Bitcoin exchange`],
        ["li nest", `RPN Calculator`],
        ["li nest", `Merge-insert sort`],
        ["heading", "Key Learning Outcomes:"],
        ["li", `Object-Oriented Programming fundamentals`],
        ["li", `Memory management and resource handling`],
        ["li", `Template programming and generic code`],
        ["li", `STL usage and best practices`],
        ["li", `Exception handling and type safety`],
        ["li", `Code organization and maintainability`],
        ["heading", "Skills Developed:"],
        ["li", `Object-Oriented Design`],
        ["li", `Memory Management`],
        ["li", `Error Handling`],
        ["li", `Generic Programming`],
        ["li", `Algorithm Implementation`],
        ["li", `Code Organization`],
      ],
      techno: ["Cplusplus", "Bash", "Git"],
    },
    title: "Personal information",
    role: ["1337 Coding School - CPP Modules (CPP 00-09)"],
    short: [
      `A comprehensive journey through Object-Oriented Programming in C++, consisting of 10 modules that progressively build expertise from basic concepts to advanced C++ features.`,
    ],
  },
  Inception: {
    img: require("../assets/prj/1337.png"),
    alt: "1337 Coding School - Inception",
    parent: "1337_Coding_School",
    links: ["https://www.github.com/Yassine-K/42-Inception"],
    type: "Project",
    data: {
      gal: [require("../assets/prj/dev/1337/42_Philosophers.png")],
      title: "Inception",
      desc: [
        [
          "p",
          `The Inception project challenges us to create a small infrastructure composed of different services using Docker containers. It's a hands-on experience in system administration, focusing on containerization and service orchestration using Docker.`,
        ],
        ["heading", "Purpose:"],
        [
          "li",
          `Set up a complete development environment using Docker containers.`,
        ],
        ["li", `Learn containerization and service orchestration.`],
        ["li", `Implement secure and persistent data storage.`],
        ["li", `Master Docker networking and container communication.`],
        [
          "li",
          `Configure services to work together in an isolated environment.`,
        ],
        ["heading", "Functionality:"],
        ["li bold", `NGINX Container:`],
        ["li nest", `Must listen only on port 443 (HTTPS)`],
        ["li nest", `Must use TLSv1.2 or TLSv1.3 protocol`],
        ["li nest", `Must serve as reverse proxy for WordPress`],
        ["li bold", `WordPress Container:`],
        ["li nest", `Must use php-fpm (installed and configured)`],
        ["li nest", `Must not use Apache or other web servers`],
        ["li nest", `Must be connected to MariaDB database`],
        ["li bold", `MariaDB Container:`],
        ["li nest", `Database service for WordPress`],
        ["li nest", `Must use a volume for persistent data storage`],
        ["li nest", `Must be secured with custom user/password`],
        ["heading", "Infrastructure Requirements:"],
        [
          "li",
          `All containers must be built from scratch using Alpine or Debian (I used Debian)`,
        ],
        ["li", `Each service must run in its dedicated container`],
        ["li", `Containers must restart automatically if they crash`],
        ["li", `Docker-compose must be used for orchestration`],
        ["li", `Volumes must be used for database and WordPress files`],
        ["li", `Must establish a docker-network for container communication`],
        ["li", `Environment variables must be used for sensitive data`],
        ["li", `Domain name must point to local IP (ykhayri.42.fr)`],
        ["heading", `Benefits:`],
        ["li", `Deep understanding of containerization concepts`],
        ["li", `Experience with Docker and container orchestration`],
        ["li", `Knowledge of secure service configuration`],
        ["li", `Practice with system administration tasks`],
        ["li", `Understanding of service networking and communication`],
        ["li", `Experience with environment isolation and security`],
        ["li", `Mastery of persistent data management`],
      ],
      techno: ["docker", "maria", "Wordpress", "Bash", "Git"],
    },
    title: "Personal information",
    role: ["1337 Coding School - Inception"],
    short: [
      `A Docker containerization project that challenges students to create a complete web infrastructure using multiple containers. The project focuses on setting up a secure WordPress environment with NGINX, PHP-FPM, and MariaDB, all orchestrated through Docker Compose.`,
    ],
  },
  Webserv: {
    img: require("../assets/prj/1337.png"),
    alt: "1337 Coding School - Webserv",
    parent: "1337_Coding_School",
    links: ["https://www.github.com/Yassine-K/42-Webserv"],
    type: "Project",
    data: {
      gal: [require("../assets/prj/dev/1337/42_Philosophers.png")],
      title: "Webserv",
      desc: [
        [
          "p",
          `A project that challenges students to create their own HTTP/1.1 compliant web server from scratch, similar to NGINX. The server must handle multiple clients simultaneously, process various HTTP methods, and execute CGI scripts while maintaining efficiency and stability.`,
        ],
        ["heading", "Purpose:"],
        ["li", `Create a fully functional HTTP/1.1 web server from scratch`],
        ["li", `Handle multiple client connections simultaneously`],
        ["li", `Process GET, POST, and DELETE HTTP methods`],
        ["li", `Serve both static and dynamic content`],
        ["li", `Execute CGI scripts for dynamic content`],
        ["li", `Implement server configuration through config files`],
        ["li", `Manage client connections efficiently using select/poll/epoll`],
        ["heading", "Functionality:"],
        ["li bold", `HTTP Protocol:`],
        ["li nest", `Parse and validate HTTP requests`],
        ["li nest", `Generate appropriate HTTP responses`],
        ["li nest", `Handle headers and status codes`],
        ["li nest", `Support persistent connections`],
        ["li bold", `Server Configuration:`],
        ["li nest", `Multiple server blocks`],
        ["li nest", `Custom error pages`],
        ["li nest", `Directory listing options`],
        ["li nest", `Client body size limits`],
        ["li nest", `Route configuration`],
        ["li bold", `Request Processing:`],
        ["li nest", `Static file serving`],
        ["li nest", `CGI script execution`],
        ["li nest", `Directory listing generation`],
        ["li nest", `Error handling`],
        ["li nest", `Request redirection`],
        ["heading", `Benefits:`],
        ["li", `Deep understanding of HTTP protocol`],
        ["li", `Experience with socket programming`],
        ["li", `Mastery of non-blocking I/O operations`],
        ["li", `Knowledge of server architecture`],
        ["li", `Practice with configuration parsing`],
        ["li", `Skills in network programming`],
        ["li", `Understanding of CGI implementation`],
        ["li", `Experience with concurrent client handling`],
      ],
      techno: ["Cplusplus", "Php", "node", "python", "Bash", "Git"],
    },
    title: "Personal information",
    role: ["1337 Coding School - Webserv"],
    short: [
      `A project that involves creating a HTTP/1.1 compliant web server from scratch in C++. Students must implement server functionality similar to NGINX, handling multiple clients, various HTTP methods, and CGI execution, all while adhering to the C++98 standard.`,
    ],
  },
};

const dev: Set = {
  Wspr: {
    alt: "Weak Signal Propagation Reporter",
    parent: "Freelance_-_Web_Development",
    type: "Project",
    links: ["https://wspr.hb9vqq.ch/"],
    data: {
      gal: [require("../assets/prj/dev/Freelance/wspr.png")],
      title: "Bringing Clarity to Radio Signal Propagation",
      desc: [
        [
          "p",
          `This interactive web application allows ham radio enthusiasts to explore and analyze data from the Weak Signal Propagation Reporter (WSPR) network. WSPR data provides valuable insights into how radio signals travel over long distances under various atmospheric conditions.`,
        ],
        ["heading", `Key Features:`],
        [
          "li",
          `Search by Criteria: Users can search WSPR data using a powerful search bar, filtering by period, callsign, locator, band, power, SNR (Signal-to-Noise Ratio), and distance.`,
        ],
        [
          "li",
          `Interactive Visualization: Search results are presented in an interactive interface, allowing users to explore trends and patterns in signal propagation.`,
        ],
        [
          "li",
          `Save & Share: Users can save their customized searches for future reference and easily share them with others for collaboration.`,
        ],
        ["heading", `This project demonstrates my skills in:`],
        ["li", `Front-End Development`],
        ["li", `Data Visualization`],
        ["li", `User Interface Design`],
        ["heading", `Libraries:`],
        ["li", `ChartJS`],
      ],
      techno: ["Html5", "Css3", "Sass", "Javascript", "Mysql"],
    },
    title: "Weak Signal Propagation Reporter",
    role: ["Weak Signal Propagation Reporter"],
    short: [
      `Weak Signal Propagation Reporter, the goal was to create a web app that allows users to access and visualize WSPR data. It allows them to search by period, callsign, locator, band, power, SNR, and distance. Users can also save their searches and share them with others. The web app can be used to answer questions about propagation conditions, such as the best band and time to contact a particular location.`,
    ],
  },
  SVG_Animation: {
    alt: "SVG CSS Animation",
    parent: "Freelance_-_Web_Development",
    type: "Project",
    data: {
      gal: [require("../assets/prj/dev/Freelance/css_an.mp4")],
      title: "Bringing a Logo to Life with Animation",
      desc: [
        [
          "p",
          `This project involved creating a dynamic and engaging animation for a client's existing SVG logo. The goal was to enhance the brand identity and capture user attention on their website or application.`,
        ],
        ["heading", `Task:`],
        ["p", `Animate the SVG Logo:`],
        [
          "li",
          `Create a dynamic and visually appealing representation of the existing logo.`,
        ],
        [
          "p",
          `Sequential Animation Steps: Implement the animation in a specific order:`,
        ],
        [
          "li",
          `"S" Shape Drawing: Animate the "S" shape to appear by drawing it from top to bottom.`,
        ],
        [
          "li",
          `Outer Circle Animation: Make the outer circle appear, enlarge in size, and rotate simultaneously.`,
        ],
        [
          "li",
          `Spinner Integration: Add a spinner element within the outer circle to create a continuous animation loop.`,
        ],
        [
          "p",
          `I used CSS's preprocessor SCSS to achieve the desired visual effects and ensure smooth performance.`,
        ],
      ],
      techno: ["Html5", "Css3", "Sass"],
    },
    title: "SVG CSS Animation",
    role: ["SVG CSS Animation"],
    short: [
      `The client had an SVG logo he wanted to animate using CSS and use it as a loader for his website.
      The logo consists of a simple 'S' shape in an outer circle.`,
    ],
  },
  Car_rental: {
    alt: "Car rental template",
    parent: "Freelance_-_Web_Development",
    type: "Project",
    data: {
      gal: [require("../assets/prj/dev/Freelance/carR.png")],
      title: "Responsive Product Offer List Template (Rental Cars)",
      desc: [
        [
          "p",
          `This project showcases a clean and responsive HTML template designed as a search result list for rental cars. Built entirely from scratch using just HTML and CSS, it demonstrates my ability to create well-structured code that translates into a visually appealing and user-friendly interface.`,
        ],
        ["heading", `Key Features:`],
        [
          "li",
          `Built from Scratch: No external libraries or frameworks used, showcasing core HTML and CSS skills.`,
        ],
        [
          "li",
          `Responsive Design: Adapts seamlessly across desktop, tablet, and mobile devices.`,
        ],
        ["li", `Clean Code: Well-organized and maintainable HTML structure.`],
        [
          "li",
          `Pixel-Perfect Design Implementation: This project involved meticulously translating the provided design mockup (JPG) into a fully functional HTML and CSS representation. My focus was on achieving a pixel-perfect replica, ensuring the website's visual elements flawlessly match the intended user experience.`,
        ],
        ["heading", "Additional Info:"],
        [
          "li",
          `Ideal for showcasing product offer lists or search result pages.`,
        ],
        [
          "li",
          `Highlights proficiency in building user-friendly interfaces with HTML and CSS.`,
        ],
      ],
      techno: ["Html5", "Css3", "Sass"],
    },
    title: "Car rental template",
    role: ["Car rental template"],
    short: [
      `The client had a JPG of an existing webpage and wanted to convert it to a pixel-perfect, responsive, and functional template.`,
    ],
  },
  Sidebar_clone: {
    alt: "Sidebar Clone",
    parent: "Freelance_-_Web_Development",
    type: "Project",
    data: {
      gal: [require("../assets/prj/dev/Freelance/sidebar.mp4")],
      title: "Interactive and Responsive Sidebar with Dark/Light mode switch:",
      desc: [
        [
          "p",
          `This project involved meticulously recreating a client-provided sidebar design using HTML, CSS, and SCSS. The goal was to achieve a pixel-perfect  representation while ensuring full functionality and responsiveness across various devices.`,
        ],
        ["heading", `Key Features:`],
        ["p", `Functional Dark/Light Mode:`],
        [
          "li",
          `The sidebar's layout, visuals, and styling meticulously match the client's reference link, guaranteeing a visually consistent experience that aligns with their vision.`,
        ],
        ["p", `Flawless Design Replication:`],
        [
          "li",
          `The sidebar seamlessly adapts its functionality and appearance across desktop, tablet, and mobile devices, maintaining optimal usability for all users.`,
        ],
        ["p", `Responsive Behavior:`],
        [
          "li",
          `Inspired by the client's reference, I implemented a Dark/Light mode toggle switch within the sidebar. This user-controlled feature allows for customizable color schemes, enhancing accessibility and user experience.`,
        ],
        ["heading", "Benefits:"],
        [
          "li",
          `Enhanced User Experience: The responsive design and Dark/Light mode functionality cater to a wider range of users with diverse device preferences and accessibility needs.`,
        ],
        [
          "li",
          `Modern Development Skills: The use of SCSS highlights proficiency in contemporary front-end development tools and techniques.`,
        ],
      ],
      techno: ["Html5", "Css3", "Sass"],
    },
    title: "Sidebar Clone",
    role: ["Sidebar Clone"],
    short: [
      `The task was creating a functional, responsive sidebar with a Dark/Light mode switch just like the one that the client provided the link for.`,
    ],
  },
  Dividends: {
    alt: "Dividends",
    parent: "Freelance_-_Web_Development",
    type: "Project",
    data: {
      gal: [require("../assets/prj/dev/Freelance/figmaToHtml.mp4")],
      title: "Interactive and Responsive Sidebar with Dark/Light mode switch:",
      desc: [
        [
          "p",
          `This project involved transforming a Figma design file into a fully functional and responsive webpage, exceeding the client's expectations.`,
        ],
        ["heading", `Key Deliverables:`],
        [
          "li",
          `Responsive Webpage: The webpage seamlessly adapts its layout and content across various screen sizes (desktop, tablet, mobile), ensuring optimal user experience on all devices.`,
        ],
        [
          "li",
          `Figma to Code Conversion: The design elements and functionalities from the Figma file were meticulously translated into clean and maintainable code using HTML, CSS, and JavaScript.`,
        ],
        [
          "li",
          `Interactive Slider Implementation: A key client-requested feature, the slider component was implemented to provide a user-friendly and interactive experience.`,
        ],
        ["heading", "Benefits:"],
        [
          "li",
          `Seamless User Experience: The responsive design ensures a smooth and user-friendly experience for website visitors across all devices.`,
        ],
        [
          "li",
          `Client Vision Realized: The project successfully bridges the gap between design and development, bringing the client's Figma vision to life as a fully functional webpage.`,
        ],
      ],
      techno: ["Html5", "Css3", "Sass"],
    },
    title: "Dividends",
    role: ["Dividends"],
    short: [
      `Creating a responsive webpage from a Figma file and implementing the functionalities that the client requested.`,
    ],
  },
  LXH_Consulting: {
    alt: "LXH Consulting",
    parent: "Freelance_-_Web_Development",
    type: "Project",
    img: require("../assets/prj/LXH.png"),
    links: ["https://ixhconsulting.com/"],
    data: {
      gal: [
        require("../assets/prj/dev/Freelance/LXH_Consulting.png"),
        require("../assets/prj/dev/Freelance/LXH_Consulting_Figma.png"),
      ],
      title: "Bilingual Website Development, UI/UX, and SEO",
      desc: [
        [
          "p",
          `I developed a bilingual informational website using WordPress and Elementor, managing everything from UI/UX design in Figma to branding, SEO optimization, and content creation. The website delivers static content in both English and Arabic, focusing on user experience and search engine visibility.`,
        ],
        ["heading", `Key Deliverables:`],
        ["li", `Fully functional bilingual WordPress website`],
        ["li", `UI/UX design using Figma`],
        ["li", `Branding guidelines (logo, typography, color scheme)`],
        ["li", `Elementor-based page customization`],
        ["li", `SEO optimization for improved search engine ranking`],
        ["li", `Extended and written content in English and Arabic`],
        ["heading", "Benefits:"],
        ["li", `Seamless multilingual user experience in English and Arabic`],
        ["li", `Consistent branding and visual identity`],
        ["li", `Responsive design for mobile, tablet, and desktop`],
        ["li", `Enhanced search engine visibility through SEO optimization`],
        ["li", `Engaging content tailored for the target audience`],
        ["li", `Easy future content updates via Elementor`],
        ["heading", "Skills:"],
        ["li", `UI/UX Design`],
        ["li", `Branding`],
        ["li", `SEO Optimization`],
        ["li", `Content Creation`],
        ["li", `Bilingual Content Integration`],
      ],
      techno: [
        "Figma",
        "Wordpress",
        "Elementor",
        "Php",
        "Javascript",
        "Html5",
        "Css3",
        "Ill",
      ],
    },
    title: "LXH Consulting",
    role: ["LXH Consulting"],
    short: [
      `The project required the development of a bilingual informational website using WordPress and Elementor. Responsibilities included designing the UI/UX, creating branding guidelines, implementing SEO strategies, and extending and writing content in both English and Arabic. The goal was to deliver a responsive, user-friendly site that enhances search visibility and provides a seamless multilingual experience.`,
    ],
  },
};

const des: Set = {
  Logo_design: {
    img: require("../assets/prj/des/logo/louj.png"),
    alt: "Logo design",
    parent: "Freelance_-_Graphic_Design",
    type: "Project",
    data: {
      gal: [
        require("../assets/prj/des/logo/cp.png"),
        require("../assets/prj/des/logo/cp1.png"),
        require("../assets/prj/des/logo/cp2.png"),
        require("../assets/prj/des/logo/louj.png"),
        require("../assets/prj/des/logo/nar.png"),
        require("../assets/prj/des/logo/rim.png"),
        require("../assets/prj/des/logo/ra.png"),
        require("../assets/prj/des/logo/ra1.png"),
      ],
      title: "A Logo: The Cornerstone of Your Brand Identity",
      desc: [
        [
          "p",
          `Crafting memorable logos for beauty & hygiene brands, where aesthetics meet brand essence.`,
        ],
        [
          "p",
          `Deep dives into brand identity are the foundation of my logo design process. I meticulously analyze my 
        client's brand vision, values, and target audience. This, combined with inspiration and a touch of creativity, 
        allows me to craft expressive logos that are both clear and impactful, perfectly capturing the essence of the brand.`,
        ],
      ],
      techno: ["Ill", "Photo"],
    },
    title: "A Logo: The Cornerstone of Your Brand Identity",
    role: ["Logo design"],
    short: [
      `A well-designed logo is the cornerstone of any brand identity. It visually communicates your brand's essence, values,
     and goals, leaving a lasting impression on your audience. That's where I come in. I help brands elevate their image by creating
      or refining logos that are memorable, impactful, and perfectly aligned with their vision.`,
    ],
  },
  "Label_&_package_design": {
    img: require("../assets/prj/des/label&pack/mama.png"),
    alt: "Label & package design",
    parent: "Freelance_-_Graphic_Design",
    type: "Project",
    data: {
      gal: [
        require("../assets/prj/des/label&pack/chris.png"),
        require("../assets/prj/des/label&pack/chris1.png"),
        require("../assets/prj/des/label&pack/cof.png"),
        require("../assets/prj/des/label&pack/hon.png"),
        require("../assets/prj/des/label&pack/louj.jpg"),
        require("../assets/prj/des/label&pack/louj1.jpg"),
        require("../assets/prj/des/label&pack/louj2.jpg"),
        require("../assets/prj/des/label&pack/mama.png"),
        require("../assets/prj/des/label&pack/nar.png"),
        require("../assets/prj/des/label&pack/nay.png"),
        require("../assets/prj/des/label&pack/nay1.jpg"),
        require("../assets/prj/des/label&pack/nay2.jpg"),
        require("../assets/prj/des/label&pack/org.png"),
        require("../assets/prj/des/label&pack/ra.png"),
        require("../assets/prj/des/label&pack/sof.png"),
        require("../assets/prj/des/label&pack/sof1.png"),
        require("../assets/prj/des/label&pack/sof2.png"),
      ],
      title: "Unbox Your Brand Identity: Label & Packaging Design",
      desc: [
        [
          "p",
          `My design process starts with a deep dive into your brand and target audience. This includes gathering assets, information, and audience data from you directly. Then, I conduct a thorough competitor analysis to understand the existing marketplace landscape. With this comprehensive understanding, I embark on a creative exploration phase, seeking inspiration and resources to bring your brand's unique vision to life. While the process may seem intricate, it's this meticulous approach that allows me to craft truly innovative designs that stand out from the crowd.`,
        ],
      ],
      techno: ["Ill", "Photo"],
    },
    title: "Personal information",
    role: ["Label & package design"],
    short: [
      `In a crowded marketplace, captivating packaging is your product's silent salesperson.
     My mission is to bridge the gap between your vision and your target audience. Through in-depth research
      and creative collaboration, I design unique and eye-catching labels and packaging that grab attention, tell your brand story,
       and drive sales.`,
    ],
  },
  Flyer_design: {
    img: require("../assets/prj/des/flyer/chris.png"),
    alt: "Flyer design",
    parent: "Freelance_-_Graphic_Design",
    type: "Project",
    data: {
      gal: [
        require("../assets/prj/des/flyer/chris1.png"),
        require("../assets/prj/des/flyer/chris.png"),
        require("../assets/prj/des/flyer/chris2.png"),
        require("../assets/prj/des/flyer/louj.png"),
      ],
      title: "Flyers that Fly: Crafting Eye-Catching Calls to Action",
      desc: [
        [
          "p",
          `My flyer design process aligns with my overall approach, ensuring impactful communication that resonates with your target audience. Here's how I leverage technology to translate your vision into engaging flyers:`,
        ],
        [
          "li",
          `Design Software: I utilize industry-standard software like Adobe Photoshop and Illustrator to create high-quality visuals, manipulate images, and craft compelling layouts.`,
        ],
        [
          "li",
          `Typography & Hierarchy: Effective use of typography and visual hierarchy guides viewers through the flyer, emphasizing key information and calls to action.`,
        ],
        [
          "li",
          `Stock Photography & Illustration: I can incorporate high-quality stock photos or create custom illustrations to enhance the visual appeal and effectively communicate your message.`,
        ],
        [
          "li",
          `Brand Consistency: Design elements will seamlessly integrate with your existing brand guidelines, ensuring visual cohesion across all marketing materials.`,
        ],
      ],
      techno: ["Ill", "Photo"],
    },
    title: "Personal information",
    role: ["Flyer design"],
    short: [
      `In a world of fleeting glances, make your message soar. I design flyers that capture attention, tell your story in a flash, and compel action. Let's craft flyers that truly fly off the shelves (or mailboxes).`,
    ],
  },
  Vectorization: {
    img: require("../assets/prj/des.jpg"),
    alt: "Vectorization",
    parent: "Freelance_-_Graphic_Design",
    type: "Project",
    data: {
      gal: [
        require("../assets/prj/des/manip/ba.jpg"),
        require("../assets/prj/des/manip/ba1.jpg"),
        require("../assets/prj/des/manip/ba2.jpg"),
      ],
      title: "Transforming Pixels into Precision",
      desc: [
        [
          "p",
          `Bringing your existing logos and graphics into the digital age is a key step in creating versatile design assets.  My approach to vectorization involves meticulous attention to detail.  First, I carefully analyze the original image, identifying key elements and color palettes. Then, using specialized software, I meticulously trace and recreate the image using mathematical paths and shapes. This meticulous process ensures the final vector graphic retains the essence of the original while offering infinite scalability and crisp detail, allowing you to use it across various platforms without sacrificing quality.`,
        ],
      ],
      techno: ["Ill", "Photo"],
    },
    title: "Personal information",
    role: ["Vectorization"],
    short: [
      `Ever wonder how logos can be used on everything from business cards to billboards without losing quality? That's the magic of vectorization! I can transform your existing pixel-based logos and graphics into clean, vector formats. This means you can resize them infinitely without any blurriness, ensuring your brand looks sharp on any platform.`,
    ],
  },
};

const openDev: Set = {
  OpenDevEd_Journey: {
    alt: "Open Development & Education",
    img: require("../assets/prj/ODE.png"),
    parent: "OpenDevEd",
    type: "Project",
    links: [
      "https://www.linkedin.com/company/opendeved",
      "https://www.github.com/OpenDevEd",
      "https://opendeved.net/",
    ],
    data: {
      gal: [
        require("../assets/prj/dev/open/official.png"),
        require("../assets/prj/dev/open/myEd.png"),
        require("../assets/prj/dev/open/evedFig.png"),
        require("../assets/prj/dev/open/eved.png"),
      ],
      title: "Open Development & Education Internship",
      desc: [
        [
          "p",
          `Open Development and Education Ltd (OpenDevEd) is a consultancy firm dedicated to enhancing teaching
        and learning in diverse countries. Their website showcases their agile and adaptable approach to educational reform.
        OpenDevEd focuses on research-driven strategies, technology integration where relevant, and ensuring equitable access
        to quality education, particularly in developing nations.`,
        ],
        ["heading", `Key Skills Developed:`],
        ["p", `Front-End Development:`],
        [
          "li",
          `Showed proficiency in building responsive websites using frameworks like ReactJS, or CMS like WordPress and Elementor,
         along with customizing plugins to achieve desired functionalities.`,
        ],
        ["p", `UI/UX Design:`],
        [
          "li",
          `Demonstrated ability to translate design mockups into functional and user-friendly interfaces
         while adhering to accessibility best practices.`,
        ],
        ["p", `Technical Skills:`],
        [
          "li",
          `Worked with technologies such as HTML, CSS, SCSS, JavaScript, TypeScript, ReactJs, and MaterilaUI.`,
        ],
        ["p", `SEO Optimization:`],
        [
          "li",
          `Developed a strong understanding of Search Engine Optimization (SEO) best practices and how to implement them to improve a website's visibility and performance.`,
        ],
        ["p", `Collaboration:`],
        [
          "li",
          `Worked alongside the OpenDevEd UI/UX team to build their professional website.`,
        ],
        [
          "li",
          `Collaborated with the Back-end team to build one of the company's apps, a Publication Management System.`,
        ],
        ["heading", `Projects:`],
        ["a", "https://opendeved.net/", `Open Development & Education:`],
        [
          "li",
          `Built the company's professional website alongside the UI/UX team.`,
        ],
        [
          "li",
          `Brought the Figma design to life using WordPress and Elementor.`,
        ],
        ["li", `Extended plugin capabilities through tailored modifications.`],
        ["li", `Optimized the website for maximum performance.`],
        ["a", "https://my.educationevidence.io/", `My Education Evidence:`],
        [
          "li",
          `Collaborated with the back-end team to lay the foundation of the publication management system.`,
        ],
        [
          "li",
          `Honed my front-end development skills and dived deeper into ReactJS and TypeScript.`,
        ],
        [
          "li",
          `Ensured to give users the best experience across all platforms.`,
        ],
        ["a", "https://educationevidence.io/", `Evidence in Education:`],
        [
          "li",
          `This project stands as a testament to my comprehensive web development skillset.
           I independently designed the user interface and user experience for the webpage,
            prioritizing accessibility. Following the design principles, I translated it into a fully
             functional website using HTML, CSS, SCSS, and JavaScript.`,
        ],
        ["heading", "Beyond Technical Skills: A Year of Growth at OpenDevEd:"],
        [
          "p",
          `This internship at Open Development & Education Ltd (OpenDevEd) wasn't just about technical development. 
          For nearly a year, I gained valuable skills in web development and user experience (UX) design. 
          Beyond the technical learning, the internship fostered a rich and rewarding experience.`,
        ],
        [
          "li",
          "Inspiring Colleagues: I had the privilege of working alongside a fantastic team, learning from their expertise, and establishing valuable professional connections. Networking with these individuals broadened my professional network and opened doors to future opportunities.",
        ],
        [
          "li",
          "Collaborative Problem-Solving: Brainstorming sessions with fellow developers fostered a collaborative spirit. Working together, we tackled challenges and developed innovative solutions, enhancing my team communication and problem-solving skills.",
        ],
      ],
      techno: [
        "Html5",
        "Css3",
        "Sass",
        "Javascript",
        "ReactJs",
        "TypeScript",
        "Materialui",
        "Wordpress",
        "Elementor",
        "Php",
        "Figma",
        "Git",
      ],
    },
    title: "Open Development & Education",
    role: ["Open Development & Education"],
    short: [
      `My internship at Open Development & Education Ltd (OpenDevEd) provided a transformative experience, allowing
       me to contribute to their mission of enhancing teaching and learning in diverse countries. OpenDevEd's commitment
        to research-driven strategies, technology integration, and equitable access to quality education deeply resonated
         with my values.`,
    ],
  },
  Internship_completion_letter: {
    type: "PDF",
    parent: "OpenDevEd",
    links: [
      require("../assets/files/Internship_completion_letter_Yassine.pdf"),
    ],
  },
};

const achievements: Set = {
  Udacity_Front_End_Dev: {
    type: "PDF",
    parent: "My_certificates",
    links: [require("../assets/files/achievements/Udacity_Front_EndDev.pdf")],
  },
  Udacity_Full_Stack_Dev: {
    type: "PDF",
    parent: "My_certificates",
    links: [require("../assets/files/achievements/Udacity_Full_Stack_Dev.pdf")],
  },
  Meta_Advanced_React: {
    type: "PDF",
    parent: "My_certificates",
    links: [require("../assets/files/achievements/Meta_Advanced_React.pdf")],
  },
  Meta_React_basics: {
    type: "PDF",
    parent: "My_certificates",
    links: [require("../assets/files/achievements/Meta_React_basics.pdf")],
  },
  Meta_JavaScript: {
    type: "PDF",
    parent: "My_certificates",
    links: [require("../assets/files/achievements/Meta_JavaScript.pdf")],
  },
  Meta_HTML_CSS_in_Depth: {
    type: "PDF",
    parent: "My_certificates",
    links: [require("../assets/files/achievements/Meta_HTML_CSS_in_Depth.pdf")],
  },
  Meta_Principles_of_UX_UI_Design: {
    type: "PDF",
    parent: "My_certificates",
    links: [
      require("../assets/files/achievements/Meta_Principles_of_UX_UI_Design.pdf"),
    ],
  },
  Meta_Version_Control: {
    type: "PDF",
    parent: "My_certificates",
    links: [require("../assets/files/achievements/Meta_Version_Control.pdf")],
  },
  Meta_Front_end_intro: {
    type: "PDF",
    parent: "My_certificates",
    links: [require("../assets/files/achievements/Meta_Front_end_intro.pdf")],
  },
  JavaScript: {
    type: "PDF",
    parent: "My_certificates",
    links: [require("../assets/files/achievements/JavaScript.pdf")],
  },
  JavaScript_for_Web: {
    type: "PDF",
    parent: "My_certificates",
    links: [require("../assets/files/achievements/JavaScript_for_Web.pdf")],
  },
  CSS_Animations: {
    type: "PDF",
    parent: "My_certificates",
    links: [require("../assets/files/achievements/CSS_Animations.pdf")],
  },
  Sass: {
    type: "PDF",
    parent: "My_certificates",
    links: [require("../assets/files/achievements/Sass.pdf")],
  },
  Wordpress: {
    type: "PDF",
    parent: "My_certificates",
    links: [require("../assets/files/achievements/Wordpress.pdf")],
  },
  Technical_SEO: {
    type: "PDF",
    parent: "My_certificates",
    links: [require("../assets/files/achievements/Technical_SEO.pdf")],
  },
  SEO: {
    type: "PDF",
    parent: "My_certificates",
    links: [require("../assets/files/achievements/SEO.pdf")],
  },
  Command_line: {
    type: "PDF",
    parent: "My_certificates",
    links: [require("../assets/files/achievements/Command_line.pdf")],
  },
};

const Dataset: Set = {
  Yassine_Khayri: {
    img: require("../assets/prj/YassineKhayri.png"),
    alt: "Yassine Khayri",
    type: "Sys",
    links: [
      "https://www.github.com/Yassine-K",
      "https://www.linkedin.com/in/yassine-khayri/",
    ],
    data: {
      title: "My story",
      desc: [
        [
          "p",
          `From drawing on paper as a kid to bringing captivating visuals to life and building seamless user experiences. I just started college when COVID hit, and since there was a worldwide lockdown, every school and university in Morocco was closed. This is where I saw the huge opportunity I was given and started diving into programming.`,
        ],
        [
          "p",
          `Front-end web development piqued my interest after the discovery phase, where I learned:`,
        ],
        ["li", `Data analysis`],
        ["li", `Android development`],
        ["li", `Front-end development`],
        ["li", `Full-stack development`],
        [
          "p",
          `Throughout this journey, I learned some programming languages in addition to the main front-end ones, such as:`,
        ],
        ["li", `MySQL`],
        ["li", `PHP`],
        ["li", `JAVA`],
        ["li", `Python`],
        [
          "p",
          `I am eager to connect with other like-minded individuals and am open to any potential collaboration or opportunity.`,
        ],
        ["heading", `Website info:`],
        [
          "p",
          `My portfolio website channels a nostalgic Windows XP aesthetic, built with modern development tools.  Leveraging ReactJS, TypeScript, and SCSS, I crafted a user interface that evokes a sense of retro charm while maintaining a performant and robust foundation.`,
        ],
        ["a", `https://portfolio-4158c.web.app/`, "Previous version"],
      ],
      techno: ["all"],
    },
    title: "Personal information",
    role: ["Front-End Developer", "Graphic Designer"],
    short: [
      `Blending front-end wizardry with captivating design to craft immersive digital experiences. Let's elevate your online presence together.`,
    ],
  },
  "1337_Coding_School": {
    type: "Folder",
    img: require("../assets/prj/1337.png"),
    role: ["1337 Coding School"],
    child: school,
    short: [
      "1337 Coding School is a unique IT training program belonging to the 42 Network. This network promotes free, project-based learning for students aged 18-30.  1337 emphasizes learning by doing through real-world projects and peer-to-peer collaboration. Students progress at their own pace, acquiring practical skills in a collaborative environment without traditional classes or professors.",
    ],
  },
  "Freelance_-_Web_Development": {
    type: "Folder",
    img: require("../assets/prj/web.jpg"),
    role: ["Freelance - Web Development"],
    short: [
      `Check out some of the projects I've completed as an independent web developer. From doing simple UI/UX tasks to building entire apps from scratch! I've learned a lot throughout my journey and honed my web development skills by adapting and overcoming all the challenges I've faced!`,
    ],
    child: dev,
  },
  "Freelance_-_Graphic_Design": {
    img: require("../assets/prj/des.jpg"),
    type: "Folder",
    role: ["Freelance - Graphic Design"],
    short: [
      `My passion for creating user-friendly experiences extends beyond code. While web development is my core focus,
       I actively leverage my design sensibilities through freelance graphic design projects. This experience has fostered
        a deeper understanding of visual hierarchy, user attention, and user-centric design principles. These skills directly
         translate into crafting intuitive and visually appealing user interfaces (UI) - a key element in building successful web applications.`,
    ],
    child: des,
  },
  OpenDevEd: {
    type: "Folder",
    img: require("../assets/prj/ODE.png"),
    role: ["Open Development & Education"],
    short: [
      "My year-long internship at OpenDevEd provided a launchpad for my web development career. I contributed to their mission of educational equity by building responsive websites and user-friendly interfaces. Beyond technical skills, I forged valuable connections with the team and developed my collaborative problem-solving skills.",
    ],
    child: openDev,
  },
  My_certificates: {
    type: "Folder",
    img: require("../assets/prj/cert.jpg"),
    role: ["My certificates"],
    short: [
      "This folder holds a collection of my hard-earned certificates, documenting my journey in development throughout 2020 and 2024. Each certificate represents a valuable learning experience that has honed my skills and expanded my knowledge base.",
    ],
    child: achievements,
  },
  My_Resume: {
    type: "PDF",
    links: [require("../assets/files/Yassine_Khayri_SD_Resume.pdf")],
  },
  Contact: {
    type: "Contact",
    role: ["Contact me"],
    data: {
      gal: [],
      title: "Let's get in touch",
      desc: [
        ["p", "sdkjbjvskdfv"],
        ["p", "svsafbabs"],
      ],
      techno: [],
    },
    short: [
      `Collaboration? Work opportunity? Potential freelance work? Or just a coffee to chat?`,
      `I'm eager to explore possibilities.`,
      `Feel free to reach out!`,
    ],
    links: [
      "https://www.github.com/Yassine-K",
      "https://www.linkedin.com/in/yassine-khayri/",
    ],
  },
  Calculator: {
    type: "Calculator",
  },
};

export default Dataset;
